body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #5442a1;
  background-image: radial-gradient(#d8d8d8 1px, transparent 0);
  background-size: 100px 100px;
}

.no-scroll {
  overflow: hidden;
}

*:focus {
  outline: none;
}

html,
body {
  width: 100%;
  height: 150%;
}

html {
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

p,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  color: #121216;
}

a:hover {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;

}

.row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.left,
.right {
  margin: 40px 0px;
  padding: 20px;
}

.left {
  width: 50%;
  height: 100%;
}

.left-wrapper {
  width: 100%;
}

.left-item {
  width: 100%;
}

.right {
  width: 50%;
  height: 100%;
}

.new-item {
  margin-top: 35px;
  margin-bottom: 15px;
}

.flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea[type="text"],
textarea[type="password"],
textarea[type="email"],
textarea[type="tel"],
textarea[type="number"],
select {
  width: 100%;
  min-height: 56px;
  border-width: 1px;
  padding: 15px 20px;
  padding-right: 10%;
  border: 1px solid #f5f5dc;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: none;
  color: #002147;
  font-size: 16px;
  resize: none;
}

input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="email"]::placeholder,
input[type="tel"]::placeholder,
input[type="number"]::placeholder,
textarea[type="text"]::placeholder,
textarea[type="password"]::placeholder,
textarea[type="email"]::placeholder,
textarea[type="tel"]::placeholder,
textarea[type="number"]::placeholder {
  color: rgba(0, 33, 71, 0.5);
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea[type="text"]:focus,
textarea[type="password"]:focus,
textarea[type="email"]:focus,
textarea[type="tel"]:focus,
textarea[type="number"]:focus,
select:focus {
  border-color: #0044cc;
}

button {
  text-decoration: none;
  border: none;
  outline: none;
  font-size: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  cursor: pointer;
}

button:hover i {
  background-color: #0044cc;
}

button i {
  background-color: #071d49;
  padding: 12px;
  border-radius: 50%;
  transition: 0.25s all ease;
}

i,
svg,
path {
  display: block;
}

select {
  margin: 28px 0 0 0;
}

.rsw-dd {
  width: fit-content;
  margin: 0;
  padding: 0;
  height: auto;
}

.answer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: burlywood;
  border-radius: 50px;
  padding: 10px;


  color: #ffffff;
  /* padding: 15px 20px; */
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.25s all ease;
  /* text-align: center; */
  /* display: flex; */
  align-items: center;
}

.delete {

  background-color: #ff0000;
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;
  transition: 0.25s all ease;
  align-items: center;
  /* display: flex; */
  align-items: center;
  border: 1px solid black;
  width: 100px;
}

.rsw-ce {
  min-height: 86px;
}

.rsw-btn {
  width: 3em !important;
  align-items: center !important;
  justify-content: center !important;
}

label {
  font-size: 18px;
  color: #002147;
  margin-bottom: 24px;
}

.right-item {
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
}

.right-item select {
  margin: 0px 0 0 0;
}

.right-flex .right-item {
  width: 50%;
}

.right-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.item-width {
  width: 100%;
}

.right-button {
  margin-top: 24px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.save {
  background-color: #071d49;
  color: #ffffff;
  padding: 15px 20px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.25s all ease;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.save:hover {
  background-color: #0044cc;
}

/* Popup */
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  backdrop-filter: blur(10px);
}

.popup-content {
  width: 50vw;
  background-color: #fefefe;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popup-close {
  position: relative;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 100%;
  justify-content: right;
}

.popup-title {
  margin-bottom: 24px;
  margin-left: 8px;
}
.mr-bot{
  margin-bottom: 24px;
}
.popup h4 {
  font-size: 24px;
  color: #002147;
}

.popup input,
.popup select {
  margin: 10px 0;
}

.popup .save {
  margin-top: 24px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  color: #ffffff;
  width: 100%;
  height: max-content;
  position: relative;
  border-bottom: 1px solid #e5e5da;
}

figure {
  margin: 0;
  padding: 0;
}

.header-logo img {
  width: 120px;
  height: auto;
  display: block;
}

@media (max-width: 991px) {
  .row {
    flex-direction: column;
  }

  .left,
  .right {
    width: 100%;
  }

  .left {
    border-bottom: 1px solid #e5e5da;
  }

  .popup-content {
    width: 90%;
  }

  .header-logo img {
    width: 90px;
  }
}